import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";

export default function Navbar({ showNavbar }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    // Cleanup function
    return () => {
      document.body.classList.remove("noScroll");
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const nav = document.querySelector(".nav");

    if (!showNavbar) {
      nav.classList.add("nav-hidden");
    } else {
      nav.classList.remove("nav-hidden");
    }

    return () => {
      nav.classList.remove("nav-hidden");
    };
  }, [showNavbar]);

  return (
    <>
      <nav className="nav">
        <a href="/" className="logo-wrapper">
          <img className="logo" src="logos/my-logo.png" alt="" />
        </a>

        <div className="desktop-menu">
          <a href="#home">Home</a>
          <a href="#projects">Portfolio</a>
          <a href="#contact">Contact</a>
        </div>

        <div className="menu-button" onClick={toggleMenu}>
          <FontAwesomeIcon className="menu-icon" icon={faBars} />
          <span>MENU</span>
        </div>
      </nav>

      <div className={`mobile-menu ${isMenuOpen ? "show-mobile-menu" : ""}`}>
        <div
          className={`mobile-menu-bg ${
            isMenuOpen ? "show-mobile-menu-bg" : ""
          }`}
        >
          <a
            href="#home"
            id={`${isMenuOpen ? "menuSlide1" : ""}`}
            onClick={() => setMenuOpen(false)}
          >
            HOME
          </a>
          <a
            href="#projects"
            id={`${isMenuOpen ? "menuSlide2" : ""}`}
            onClick={() => setMenuOpen(false)}
          >
            PORTFOLIO
          </a>
          <a
            href="#contact"
            id={`${isMenuOpen ? "menuSlide3" : ""}`}
            onClick={() => setMenuOpen(false)}
          >
            CONTACT
          </a>
        </div>
      </div>
    </>
  );
}
