import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { MdEmail } from "react-icons/md";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import "./Contact.css";

export default function Contact({ contactRef }) {
  const [state, handleSubmit] = useForm("xjvqvlnd");

  return (
    <div ref={contactRef} id="contact">
      <section>
        <h1 className="section-heading">Contact Me</h1>
        <div className="section-content">
          <div className="content-container-1">
            <ul className="contact-list">
              <li>
                <MdEmail className="contact-icon" />
                <span>rh20020421@gmail.com</span>
              </li>
              <li>
                <FaGithub className="contact-icon" />
                <a
                  href="https://github.com/Rhuyk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  github.com/Rhuyk
                </a>
              </li>
              <li>
                <FaLinkedinIn className="contact-icon" />
                <a
                  href="https://www.linkedin.com/in/feng-min-hu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  linkedin.com/in/feng-min-hu
                </a>
              </li>
            </ul>
            <a
              href="https://drive.google.com/file/d/1aPskxQIKA4dqoNGlm1kFdUpVgc7ZDD3R/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="button"
            >
              {" "}
            </a>
          </div>

          <div className="content-container-2">
            <h3 className="form-title">Send a Message</h3>
            <form onSubmit={handleSubmit}>
              <input
                className="inputBox"
                placeholder="Your Name"
                id="name"
                type="text"
                name="name"
                autoComplete="off"
                required
              />
              <ValidationError
                className="inputError"
                prefix="Name"
                field="name"
                errors={state.errors}
              />

              <input
                className="inputBox"
                placeholder="Email Address"
                id="email"
                type="email"
                name="email"
                autoComplete="off"
                required
              />
              <ValidationError
                className="inputError"
                prefix="Email input"
                field="email"
                errors={state.errors}
                message="Please provide a valid email address."
              />

              <textarea
                className="inputBox"
                placeholder="Enter Message"
                rows="5"
                cols="60"
                id="message"
                name="message"
                autoComplete="off"
                required
              />
              <ValidationError
                className="inputError"
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              {!state.succeeded && (
                <button type="submit" disabled={state.submitting}>
                  Send Message
                </button>
              )}

              {state.succeeded && (
                <h4 className="submission-message">
                  Message Submitted Successfully!
                </h4>
              )}
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
