import React, { useRef, useState, useEffect } from "react";
import { debounce } from "lodash";
import "./Parallax.css";

export default function Parallax({ setShowNavbar }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const parallax = useRef(null);
  const background = useRef(null);
  const balloon1 = useRef(null);
  const balloon1_light = useRef(null);
  const balloon2 = useRef(null);
  const balloon2_light = useRef(null);
  const cloud1 = useRef(null);
  const cloud2 = useRef(null);
  const moon = useRef(null);
  const moon_light = useRef(null);
  const mountain1 = useRef(null);
  const mountain2 = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const layers = [
      background,
      balloon1,
      balloon1_light,
      balloon2,
      balloon2_light,
      cloud1,
      cloud2,
      moon,
      moon_light,
      mountain1,
      mountain2,
    ];

    layers.forEach((layer) => {
      if (layer.current) {
        observer.observe(layer.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let balance = window.innerHeight * 2.5 - window.innerWidth / 2;
      let value = 0;

      if (parallax.current) {
        const rect = parallax.current.getBoundingClientRect();
        if (rect.top < 0) {
          value = Math.max(0, -rect.top);
        }

        if (rect.top < 1 && rect.bottom > 1) {
          setShowNavbar(false);
        } else {
          setShowNavbar(true);
        }
      }

      requestAnimationFrame(() => {
        moon.current.style.backgroundPositionY = value * 0.9 + "px";
        moon_light.current.style.backgroundPositionY = value * 0.9 + "px";

        cloud1.current.style.backgroundPositionX =
          value * -0.8 - balance + "px";
        cloud2.current.style.backgroundPositionX =
          value * -0.4 - balance + "px";
        cloud1.current.style.backgroundPositionY = value * 0.7 + "px";
        cloud2.current.style.backgroundPositionY = value * 0.8 + "px";

        balloon1.current.style.backgroundPositionX =
          value * -0.2 - balance + "px";
        balloon1_light.current.style.backgroundPositionX =
          value * -0.2 - balance + "px";
        balloon1.current.style.backgroundPositionY = value * 0.5 + "px";
        balloon1_light.current.style.backgroundPositionY = value * 0.5 + "px";

        balloon2.current.style.backgroundPositionX =
          value * -0.1 - balance + "px";
        balloon2_light.current.style.backgroundPositionX =
          value * -0.1 - balance + "px";
        balloon2.current.style.backgroundPositionY = value * 0.6 + "px";
        balloon2_light.current.style.backgroundPositionY = value * 0.6 + "px";

        if (value > window.innerHeight * 0.8) {
          let v = value - window.innerHeight * 0.8;

          mountain2.current.style.transform = `translateY(${v * 0.25 - 85}px)`;
          mountain1.current.style.transform = `translateY(${v * 0.1 - 20}px)`;
        }
      });
    };

    const handleScrollDebounced = debounce(handleScroll, 20);

    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [setShowNavbar]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);

      let balance = window.innerHeight * 2.5 - window.innerWidth / 2;
      let value = window.scrollY;

      requestAnimationFrame(() => {
        cloud1.current.style.backgroundPositionX =
          value * -0.8 - balance + "px";
        cloud2.current.style.backgroundPositionX =
          value * -0.4 - balance + "px";

        balloon1.current.style.backgroundPositionX =
          value * -0.2 - balance + "px";
        balloon1_light.current.style.backgroundPositionX =
          value * -0.2 - balance + "px";
        balloon2.current.style.backgroundPositionX =
          value * -0.1 - balance + "px";
        balloon2_light.current.style.backgroundPositionX =
          value * -0.1 - balance + "px";
      });
    };

    const handleResizeDebounced = debounce(handleResize, 20);

    window.addEventListener("resize", handleResizeDebounced);

    return () => {
      window.removeEventListener("resize", handleResizeDebounced);
    };
  }, []);

  return (
    <>
      <div id="parallax" />
      <div
        className="nonparallax"
        style={{ display: isMobile ? "block" : "none" }}
      ></div>

      <div
        ref={parallax}
        className="parallax"
        style={{ display: isMobile ? "none" : "block" }}
      >
        <div ref={background} id="background" className="parallax-layer"></div>
        <div ref={moon} id="moon" className="parallax-layer"></div>
        <div ref={cloud2} id="cloud2" className="parallax-layer"></div>
        <div ref={cloud1} id="cloud1" className="parallax-layer"></div>
        <div ref={mountain2} id="mountain2" className="parallax-layer"></div>
        <div ref={moon_light} id="moon-light" className="parallax-layer"></div>
        <div ref={mountain1} id="mountain1" className="parallax-layer"></div>
        <div id="surface" className="parallax-layer"></div>

        <div ref={balloon2} id="balloon2" className="parallax-layer"></div>
        <div
          ref={balloon2_light}
          id="balloon2-light"
          className="parallax-layer"
        ></div>

        <div ref={balloon1} id="balloon1" className="parallax-layer"></div>
        <div
          ref={balloon1_light}
          id="balloon1-light"
          className="parallax-layer"
        ></div>
      </div>
    </>
  );
}
