import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <span>Thanks for your visit!</span>
        <span>I hope my portfolio left a positive impression.</span>
        <span>Feel free to connect and share your thoughts.</span>
        <img src="images/footer-gif.gif" alt="" />
        <span>Designed & Built by Feng-Min Hu (2024)</span>
      </div>
    </>
  );
}
