import React, { useState, useEffect } from "react";
import { IoMailOutline } from "react-icons/io5";
import { FiGithub, FiLinkedin } from "react-icons/fi";
import "./Sides.css";

export default function Sides() {
  const [currentSection, setCurrentSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["#home", "#parallax", "#projects", "#contact"];
      const scrollCenter = window.scrollY + window.innerHeight / 2;

      // Check if at the top of the page
      if (scrollCenter < window.innerHeight) {
        setCurrentSection("");
        return;
      }

      sections.forEach((sectionId) => {
        const section = document.querySelector(sectionId);
        if (section) {
          const sectionTop = section.offsetTop;

          if (scrollCenter >= sectionTop) {
            setCurrentSection(sectionId);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="left-side">
        <ul>
          <li>
            <a
              href="mailto:rh20020421@gmail.com"
              title="Email"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoMailOutline />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Rhuyk"
              title="GitHub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiGithub />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/feng-min-hu"
              title="Linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiLinkedin />
            </a>
          </li>
        </ul>
      </div>

      <div className="right-side">
        <ul className="dots">
          <li>
            <a href="# ">
              <span
                className={`dot ${currentSection === "" ? "active-dot" : ""}`}
              />
              <span className="dot-label">INTRO</span>
            </a>
          </li>
          <li>
            <a href="#home">
              <span
                className={`dot ${
                  currentSection === "#home" ? "active-dot" : ""
                }`}
              />
              <span className="dot-label">ABOUT</span>
            </a>
          </li>
          <li>
            <a href="#parallax">
              <span
                className={`dot ${
                  currentSection === "#parallax" ? "active-dot" : ""
                }`}
              />
              <span className="dot-label">PARALLAX</span>
            </a>
          </li>
          <li>
            <a href="#projects">
              <span
                className={`dot ${
                  currentSection === "#projects" ? "active-dot" : ""
                }`}
              />
              <span className="dot-label">PORTFOLIO</span>
            </a>
          </li>
          <li>
            <a href="#contact">
              <span
                className={`dot ${
                  currentSection === "#contact" ? "active-dot" : ""
                }`}
              />
              <span className="dot-label">CONTACT</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
