import React, { useState } from "react";
import "./Flipper.css";

export default function Flipper({ icon, name, small }) {
  const [isFlipped, setIsFlipped] = useState(false);

  let flipBackTimeout;

  const handleMouseEnter = () => {
    clearTimeout(flipBackTimeout);
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    flipBackTimeout = setTimeout(() => {
      setIsFlipped(false);
    }, 1000);
  };

  return (
    <li
      className={`item-wrapper ${small ? "small-item" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`item ${isFlipped ? "flipped" : ""}`}>
        {icon}
        <div className="name">{name}</div>
      </div>
    </li>
  );
}
