import React from "react";
import "./Projects.css";
import Project from "../../components/project/Project";

export default function Projects() {
  return (
    <div id="projects">
      <div className="main">
        <section id="projects-section">
          <h1 className="section-heading">Portfolio</h1>
          <div className="section-content">
            <Project
              name={"PortfolioPal"}
              img={"projects/portfolioPal.gif"}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Nisi, totam sunt ipsam repudiandae dolorum amet dolores magnitempora, error enim quibusdam doloribus commodi similiquesoluta debitis mollitia eveniet veritatis nulla!"
              }
              techList={
                <>
                  <li>React Native</li>
                  <li>Firebase</li>
                  <li>Rapid API</li>
                  <li>OpenAI API</li>
                  <li>Youtube API</li>
                </>
              }
              githubLink={"https://github.com/ErickLao123/2023-S2-51-AIVestor"}
              isFeatured={true}
            />

            <Project
              name={"AI Chess App"}
              img={"projects/ai-chess.gif"}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Nisi, totam sunt ipsam repudiandae dolorum amet dolores magnitempora, error enim quibusdam doloribus commodi similiquesoluta debitis mollitia eveniet veritatis nulla!"
              }
              techList={
                <>
                  <li>Java</li>
                  <li>Apache Derby</li>
                  <li>JDBC</li>
                  <li>NetBeans</li>
                </>
              }
              githubLink={"https://github.com/pjsiri/AI-Chess-Game"}
              isInverted={true}
              isFeatured={true}
            />

            <Project
              name={"Bouncing Rapeti Website"}
              img={"projects/bouncy-web.png"}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Nisi, totam sunt ipsam repudiandae dolorum amet dolores magnitempora, error enim quibusdam doloribus commodi similiquesoluta debitis mollitia eveniet veritatis nulla!"
              }
              techList={
                <>
                  <li>JavaScript</li>
                  <li>React</li>
                  <li>Firebase</li>
                  <li>SwiperJS</li>
                  <li>EmailJS</li>
                </>
              }
              externalLink={"https://bouncing-rapeti.web.app/"}
            />

            <Project
              name={"Old Personal Website"}
              img={"projects/my-web-v1.png"}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Nisi, totam sunt ipsam repudiandae dolorum amet dolores magnitempora, error enim quibusdam doloribus commodi similiquesoluta debitis mollitia eveniet veritatis nulla!"
              }
              techList={
                <>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                </>
              }
              githubLink={"https://github.com/pjsiri/AI-Chess-Game"}
              externalLink={"https://fengminhu.com/"}
              isInverted={true}
            />

            <Project
              name={"2-Player Chess App"}
              img={"projects/chess-game.gif"}
              description={
                "A fun and fully functional 2-player chess game that automatically records every move you make. With its embedded database feature, you can save, load, and replay past matches."
              }
              techList={
                <>
                  <li>Java</li>
                  <li>Apache Derby</li>
                  <li>JDBC</li>
                  <li>NetBeans</li>
                </>
              }
              githubLink={"https://github.com/Rhuyk/COMP603-Project-2-Chess"}
            />

            <Project
              name={"Mobile Phone Virus Simulator"}
              img={"projects/phone-virus.gif"}
              description={
                "A phone virus transmission simulator that simulates how a virus spreads to nearby phones, and only one phone can be repaired at a time. This program utilizes multi-threading and synchronization techniques, as well as GUI animations."
              }
              techList={
                <>
                  <li>Java</li>
                  <li>NetBeans</li>
                </>
              }
              githubLink={"https://github.com/Rhuyk/Mobile-Phone-Virus-Sim"}
              isInverted={true}
            />

            <Project
              name={"Noise Removal App"}
              img={"projects/noise-removal.gif"}
              description={
                "Noise Removal Application is a tool designed to clean images by removing impulse noises. This application utilzes a median filter technique by using quick sort algorithm to identify the median colour of each 9-pixel window, then save and outputs the refined image."
              }
              techList={
                <>
                  <li>Java</li>
                  <li>NetBeans</li>
                </>
              }
              githubLink={"https://github.com/Rhuyk/Noise-Removal-App"}
            />

            <Project
              name={"Maze Program"}
              img={"projects/maze.gif"}
              description={
                "A maze program that can load mazes from text files. It constructs a binary tree to navigate the maze, then identify the optimal route and walk through it from the starting node to the exiting node."
              }
              techList={
                <>
                  <li>Java</li>
                  <li>NetBeans</li>
                </>
              }
              githubLink={"https://github.com/Rhuyk/Maze-Program"}
              isInverted={true}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
