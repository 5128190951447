import React, { useState, useRef } from "react";
import Navbar from "./components/navbar/Navbar";
import Vanta from "./components/vanta/Vanta";
import Home from "./pages/home-page/Home";
import Parallax from "./components/parallax/Parallax";
import Projects from "./pages/projects-page/Projects";
import Contact from "./pages/contact-page/Contact";
import Footer from "./components/footer/Footer";

function App() {
  const [showNavbar, setShowNavbar] = useState(true);
  const contactRef = useRef(null);

  return (
    <>
      <Navbar showNavbar={showNavbar} />
      <Vanta contactRef={contactRef} />
      <Home />
      <Parallax setShowNavbar={setShowNavbar} />
      <Projects />
      <Contact contactRef={contactRef} />
      <Footer />
    </>
  );
}

export default App;
