import React, { useEffect, useRef } from "react";
import NET from "vanta/src/vanta.net";
import "./Vanta.css";

const Vanta = ({ contactRef }) => {
  const vantaRef = useRef(null);
  const homeRef = useRef();

  const scrollDown = ({ ref }) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    let vantaEffect = null;

    const distance =
      window.innerWidth > 600
        ? window.innerWidth > 1200
          ? window.innerWidth > 1800
            ? 40
            : 35
          : 30
        : 25;

    const initializeVanta = () => {
      vantaEffect = NET({
        el: "#vanta",
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        color: "#7DF9FF",
        backgroundColor: "#000010",
        points: 10.0,
        maxDistance: distance,
        spacing: 30,
      });
    };

    initializeVanta();

    // Intersection Observer to observe when the Vanta component is out of view
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting && vantaEffect) {
        // If Vanta component is out of view, destroy the Vanta effect
        vantaEffect.destroy();
        vantaEffect = null;
      } else if (entry.isIntersecting && !vantaEffect) {
        // If Vanta component is back in view, re-initialize the Vanta effect
        initializeVanta();
      }
    });

    observer.observe(homeRef.current);
    observer.observe(contactRef.current);

    // Cleanup function
    return () => {
      observer.disconnect();
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, [contactRef]);

  return (
    <>
      <div id="vanta" ref={vantaRef}></div>
      <div className="cover">
        <div className="coverTitleContainer">
          <span className="cover-text1">Hi, my name is</span>
          <span className="cover-text2">Feng-Min Hu</span>
          <span className="cover-text3">Software Developer</span>
        </div>
        <div className="coverButtonContainer">
          <div
            onClick={() => scrollDown({ ref: homeRef })}
            className="coverScrollButton"
          >
            <svg
              className="arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" />
            </svg>
          </div>
        </div>
      </div>
      <div ref={homeRef}></div>
    </>
  );
};

export default Vanta;
